.App {
  text-align: center;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

#auth {
  padding: 0 24px 0 24px;
}

/* Avoid coloring autofilled inputs */
input:autofill {
  caret-color: inherit !important;
  -webkit-text-fill-color: inherit !important;
}

/* Doesn't seem to work... */
@media only screen and ((max-height: 600px) and (orientation: portrait)) or ((max-width: 600px) and (orientation: landscape)) {
  .non-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 240px) and (orientation: portrait) {
  .App-logo {
    display: none !important;
  }
  .App-header {
    padding-top: 0;
  }
  #auth {
    padding: 0;
  }
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .non-mobile {
    display: none !important;
  }
  .App-header {
    width: 100%;
    min-height: 100vh;
  }
  body {
    background-color: black !important;;
  }
}

@media only screen and (max-height: 600px) and (orientation: landscape) {
  .non-mobile {
    display: none !important;
  }
  .App-header {
    width: 100%;
    min-height: 100vh;
  }  
  body {
    background-color: black !important;
  }
}

@media only screen and (min-width: 601px) and (min-height: 601px) {
  .App {
    background: url(images/login_back.jpg) center fixed;
    background-size: cover;
    justify-content: center;
    height: 100vh;
  }

  .App-header {
    width: min(640px, 100%);
    padding: 30px;
  }
}

.App-logo {
  margin: 10px;
}

.App-header {
  display: flex;
  position: relative;
  background-color: #222;
  flex-direction: column;
  padding-top: 24px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

div.App-header.error-message h1 svg {
  color: red;
}

div.App-header.error-message .hint {
  font-style: italic;
}

div.overlay {
  position: absolute;
  margin-top: -24px;
  margin-left: -30px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

div.overlay .inner {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
}

div.overlay div[role=progressbar] {
  position: absolute;
  left: 210px; /* (width of App-header - width of progress bar) / 2 => (500 - 80) / 2 */
  top: 150px;
}

.layout-align-center-stretch,
.layout-align-end-stretch,
.layout-align-space-around-stretch,
.layout-align-space-between-stretch,
.layout-align-start-stretch {
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.layout-align-center,
.layout-align-center-center,
.layout-align-center-end,
.layout-align-center-start,
.layout-align-center-stretch {
  -ms-flex-pack: center;
  justify-content: center;
}

.layout-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.layout-column > .flex {
  min-height: 0;
}

.layout-row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.layout,
.layout-column,
.layout-row {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex !important;
}

.flex {
  -ms-flex: 1;
  flex: 1;
}

.flex,
.flex-grow {
  -webkit-box-flex: 1;
  box-sizing: border-box;
}

.flex-5,
.layout-column > .flex-5,
.layout-row > .flex-5,
.flex-10,
.layout-column > .flex-10,
.layout-row > .flex-10,
.flex-15,
.layout-column > .flex-15,
.layout-row > .flex-15,
.flex-20,
.layout-column > .flex-20,
.layout-row > .flex-20,
.flex-25,
.layout-column > .flex-25,
.layout-row > .flex-25,
.flex-30,
.layout-column > .flex-30,
.layout-row > .flex-30,
.flex-33,
.layout-column > .flex-33,
.layout-row > .flex-33,
.flex-40,
.layout-column > .flex-40,
.layout-row > .flex-40,
.flex-45,
.layout-column > .flex-45,
.layout-row > .flex-45,
.flex-50,
.layout-column > .flex-50,
.layout-row > .flex-50,
.flex-55,
.layout-column > .flex-55,
.layout-row > .flex-55,
.flex-60,
.layout-column > .flex-60,
.layout-row > .flex-60,
.flex-65,
.layout-column > .flex-65,
.layout-row > .flex-65,
.flex-66,
.layout-column > .flex-66,
.layout-row > .flex-66,
.flex-70,
.layout-column > .flex-70,
.layout-row > .flex-70,
.flex-75,
.layout-column > .flex-75,
.layout-row > .flex-75,
.flex-80,
.layout-column > .flex-80,
.layout-row > .flex-80,
.flex-85,
.layout-column > .flex-85,
.layout-row > .flex-85,
.flex-90,
.layout-column > .flex-90,
.layout-row > .flex-90,
.flex-95,
.layout-column > .flex-95,
.layout-row > .flex-95,
.flex-100,
.layout-column > .flex-100,
.layout-row > .flex-100 {
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  box-sizing: border-box;
}

.flex-5,
.layout-row > .flex-5 {
  max-width: 5%;
  min-width: 5%;
  max-height: 100%;
}

.flex-5 {
  -ms-flex-preferred-size: 5% !important;
  flex-basis: 5% !important;
}

.flex-10,
.layout-row > .flex-10 {
  max-width: 10%;
  min-width: 10%;
  max-height: 100%;
}

.flex-10 {
  -ms-flex-preferred-size: 10% !important;
  flex-basis: 10% !important;
}

.flex-20,
.layout-row > .flex-20 {
  max-width: 20%;
  min-width: 20%;
  max-height: 100%;
}

.flex-20 {
  -ms-flex-preferred-size: 20% !important;
  flex-basis: 20% !important;
}

.flex-40,
.layout-row > .flex-40 {
  max-width: 40%;
  min-width: 40%;
  max-height: 100%;
}

.flex-45 {
  -ms-flex-preferred-size: 45% !important;
  flex-basis: 45% !important;
}

.flex-45,
.layout-row > .flex-45 {
  max-width: 45%;
  min-width: 45%;
  max-height: 100%;
}

.flex-45 {
  -ms-flex-preferred-size: 45% !important;
  flex-basis: 45% !important;
}

.flex-60,
.layout-row > .flex-60 {
  max-width: 60%;
  min-width: 60%;
  max-height: 100%;
}

.flex-60 {
  -ms-flex-preferred-size: 60% !important;
  flex-basis: 60% !important;
}

.flex-85,
.layout-row > .flex-85 {
  max-width: 85%;
  min-width: 85%;
  max-height: 100%;
}

.flex-85 {
  -ms-flex-preferred-size: 85% !important;
  flex-basis: 85% !important;
}

.flex-90,
.layout-row > .flex-90 {
  max-width: 90%;
  min-width: 90%;
  max-height: 100%;
}

.flex-90 {
  -ms-flex-preferred-size: 90% !important;
  flex-basis: 90% !important;
}

.error {
  background-color: rgb(255, 221, 226);
  padding-top: 5px;
  padding-bottom: 5px;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}

.account-name {
  font-weight: bold;
}

div.info {
  margin: 20px 75px;
  padding: 15px;
  text-align: left;
}

div.info svg {
  font-size: 2em;
  margin-right: 10px;
  float: left;
  position: relative;
  top: -5px;
  border-radius: 4px;
}